import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Navbar from './Navbar'
import LanguageSwitcher, { AltLanguage } from './LanguageSwitcher'

const Header = props => {
  return (
    <div id="page-header" style={{ marginTop: 0 }}>
      <div className="section-bg" style={{ backgroundColor: '#83ba09' }} />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="header-content">
              {props.title && <h1>{props.title}</h1>}
              {/* <ul className="breadcrumb">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Layout = ({ children, showHeader, pageTitle, language, className }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Navbar language={language} siteTitle={data.site.siteMetadata.title} />
        <LanguageSwitcher language={language} />
        <div className={className}>
          {showHeader && <Header title={pageTitle} />}
          {children}
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

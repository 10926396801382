import React from 'react'
import Link from 'gatsby-link'

import logo from '../images/logo.png'
import { DEFAULT_LANG } from '../utils/constants'
import { AltLanguage } from './LanguageSwitcher'

const Navbar = ({ language }) => {
  const langPrefix = (language === DEFAULT_LANG || !language) ? '' : '/fr'

  const localize = (def, fr) => {
    return langPrefix === "/fr"
      ? fr
      : def
  }

  return (
    <nav className="navbar" style={{ marginBottom: 0, borderBottom: 0 }}>
      <div className="container">
        <div className="navbar-header">
          <div className="navbar-brand">
            <Link to={langPrefix + "/"} className="logo">
              <img src={logo} alt="Guinea promise" />
            </Link>
          </div>
          <button className="navbar-toggle-btn">
            <i className="fa fa-bars" />
          </button>
        </div>
        <ul className="navbar-menu nav navbar-nav navbar-right">
          <li>
            <AltLanguage language={language}/>
          </li>
          <li>
            <Link className="navbar-item" to={langPrefix + "/"}>
              {localize("Home", "Accueil")}
            </Link>
          </li>
          <li>
            <Link className="navbar-item" to={langPrefix + "/about-us"}>
            {localize("About", "Qui sommes nous")}
            </Link>
          </li>
          <li>
            <Link
              className="navbar-item"
              activeClassName="active"
              to={langPrefix + "/about-us/team"}
            >
              {localize("Team", "L'équipe")}
            </Link>
          </li>
          <li>
            <Link className="navbar-item" to={langPrefix + "/programs"}>
              {localize("Programs", "Programmes")}
            </Link>
          </li>
          {/* <li>
        <Link className="navbar-item" to="/blog">
          Blog
        </Link>
      </li> */}
          <li>
            <a className="navbar-item" href="#contacts">
              {localize("Contacts", "Contacts")}
            </a>
          </li>
          <li>
            <a
              className="primary-button"
              href={'https://donorbox.org/guinea-promise'}
              target="_blank"
              style={{ paddingTop: 5, height: 30, marginTop: 15 }}
            >
              {localize("Donate", "Faire un don")}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar

import React from 'react'
import Link from 'gatsby-link'

import checkmarkIcon from '../images/checkmark.png'
import frFlag from '../images/fr_flag.png'
import usFlag from '../images/us_flag.png'
import { DEFAULT_LANG } from '../utils/constants'

const isFrench = (language) => {
  const langPrefix = language === DEFAULT_LANG || !language ? '' : '/fr'
  return langPrefix === '/fr'
}

const flagStyle = {width: 20, marginBottom: 0, marginRight: 16}
// const checkmarkStyle = {width: 16, marginBottom: 0, marginLeft: 16}

export const AltLanguage = ({ language }) => {

  return (
    <>
      {!isFrench(language) && 
        <a className="action-select-language" data-language="fr">
          <img style={flagStyle} src={frFlag} alt="French flag" /> Français
        </a>
      }
      {isFrench(language) &&
        <a className="action-select-language" data-language="en">
          <img style={flagStyle} src={usFlag} alt="US flag" /> English
        </a>
      }
    </>
  )
}

const LanguageSwitcher = ({ language }) => {
  const localize = (def, fr) => {
    return isFrench(language) ? fr : def
  }

  return (
    <div className="modal fade" id="language-switcher" role="dialog">
      <div className="modal-dialog modal-md" data-backdrop="false">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Change language
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{ paddingLeft: 6, paddingRight: 6 }}
          >
            <p class="lead" style={{paddingLeft: 6, paddingRight: 32}}>
              {localize(
                "Ce site est aus disponible en français",
                "This website is also available in english",
              )}
            </p>
            <div className="list-group" style={{ marginLeft: 0, marginBottom: 0 }}>
              <button className="list-group-item list-group-item-action action-select-language" data-language="fr">
                <img style={flagStyle} src={frFlag} alt="French flag" /> Français
              </button>
              <button className="list-group-item list-group-item-action action-select-language" data-language="en">
              <img style={flagStyle} src={usFlag} alt="US flag" /> English
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LanguageSwitcher

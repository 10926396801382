import React from 'react'
import _ from 'lodash'
import Layout from '../components/layout'

const NotFoundPage = ({ pageContext }) => {
  const language = _.get(pageContext, 'language')

  return (
    <Layout language={language}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
